import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private http: HttpClient
  ) { }

  getDashboardEmbedURL() {
    console.log('Getting Dashboard Embed URL');
    return this.http.get(environment.wipProcessAPIURL + '/getDashboardEmbedURL', { responseType: 'text' })
  }
}
