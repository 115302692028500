import { Component, OnInit, AfterViewInit, forwardRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Auth } from 'aws-amplify';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import {
  HomeWipProcessService

} from "app/core/_services/index";
import { environment } from '../../../environments/environment';
import { userInfo } from 'os';
import { Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { WipProgressService } from '../../core/_services/wip-progress.service';
import moment from 'moment';
// import {
//   DateAdapter,
//   MAT_DATE_FORMATS,
//   MAT_DATE_LOCALE,
//   MatDatepicker,
// } from '@angular/material';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  name: string;
  wipProcessForm: FormGroup
  isProd:boolean
  // RefreshToken: string;
  // AccessToken: string;
  // IDToken: string;
  // userInfo: any;
  // email: string;
  // realmId: string;

  @Input() deviceXs: boolean;
  yearsList = []
  date = new FormControl(new Date());
  constructor(
    private _formBuilder: FormBuilder,
    private HomeWipProcessService: HomeWipProcessService,
    public datepipe: DatePipe,
    private wipProgressService: WipProgressService,
    private sanitizer: DomSanitizer,
  ) {



  }

  realTimeProgress: Subscription;
  wipContinueProgress: Subscription;
  bindProgressData: any;
  extractProgressData:any;
  isSpinning: boolean;

  ngOnInit(): void {
    this.isProd=environment.production
    console.log("isprod",this.isProd)
    // Auth.currentSession().then(userInfo => {
    //   sessionStorage.setItem("ACCESS_TOKEN", userInfo.getAccessToken().getJwtToken())
    //   sessionStorage.setItem("ID_TOKEN", userInfo.getIdToken().getJwtToken())
    //   sessionStorage.setItem("REFRESH_TOKEN", userInfo.getRefreshToken().getToken())
    // }).then(() => {
    //   this.IDToken = sessionStorage.getItem("ID_TOKEN")
    //   this.userInfo = this.getUserInfo(this.IDToken)
    //   this.RefreshToken = sessionStorage.getItem("REFRESH_TOKEN")
    //   this.AccessToken = sessionStorage.getItem("ACCESS_TOKEN")
    //   //this.email = this.userInfo.email;
    //   this.realmId = this.userInfo['custom:realmid']
    //   //console.log("tokendata", this.AccessToken, this.IDToken, this.RefreshToken)
    // })

    this.resetProgress();
    this.resetWipContinueProgress();

    this.creatYear();

    this.initwipProcessForm();

  }

  initwipProcessForm(): void {
    try {
      this.wipProcessForm = this._formBuilder.group({
        year: [null, Validators.compose([Validators.required])],
        completedPeriod: [null, Validators.compose([Validators.required])]
      });
    } catch (error) {
      console.log(error)
    }
  }



  wipProcessSubmit(refreshOnly): void {

    try {

      this.bindProgressData = null;
      this.isSpinning = false;
      this.extractProgressData=null;

      //console.log(refreshOnly)

      const data = {

        year: this.wipProcessForm.value.year,

        // completedPeriod: this.datepipe.transform(this.wipProcessForm.value.completedPeriod, 'MM-dd-yyyy'),

        completedPeriod: moment(this.wipProcessForm.value.completedPeriod).format('yyyy-MM-DD'),

        refreshOnly: refreshOnly,

        // accessToken: this.AccessToken,

        // refreshToken: this.RefreshToken,

        // realmId: this.realmId

      };
      console.log(data);
      this.showProgress();
      this.HomeWipProcessService.runWipProcess(data).subscribe(response => {
        if (response) {
          //  this._router.navigateByUrl('/auth/login');
          // this.realTimeProgress.unsubscribe();

        }
        else {
        }
      },
        error => {
          console.log(error)
        });
    }
    catch (error) {
      console.log(error)
    }
  }


  creatYear() {
    let currentDate = new Date().getFullYear();

    this.yearsList.push(currentDate)
    for (let index = 0; index < 100; index++) {
      this.yearsList.push(currentDate - 1);
      currentDate--
    }
  }

  resetProgress() {
    console.log("RESETTING PROGRESS MESSAGES ...")
    this.wipProgressService.resetProgressMessage().subscribe((res) => {
      console.log(res, "Reset Progress Response..")
    });
  }

  resetWipContinueProgress() {
    console.log("RESETTING WIP CONTINUE PROGRESS MESSAGES ...")
    this.wipProgressService.resetWipContinueProgressMessage().subscribe((res) => {
      console.log(res, "Reset Continue Progress Response..")
    });
  }

  showProgress() {

    this.isSpinning = true;

    this.realTimeProgress = timer(0, 1000).pipe(switchMap(_ => this.wipProgressService.getConfig())).subscribe(res => {
      if(!res.toString().includes("All Transaction gathered from QBO")){
        if(res.toString().includes("PROCESS COMPLETE:")) {
          console.log("PROCESS COMPLETED !");
          //Setting the Spinner Value to False which stops the Spinner from Spinning.
          this.isSpinning = false;
          //Unsubscribing from the Progress Service once the Progress Finishes.
          this.realTimeProgress.unsubscribe();
        }
      this.bindProgressData = this.sanitizer.bypassSecurityTrustHtml(res);
      }
      else{
        this.realTimeProgress.unsubscribe();
        this.extractProgressData=res
        this.showContinueProgress()
      }
    });
  }

  showContinueProgress(){
    this.isSpinning = true;
    this.wipContinueProgress = timer(0, 1000).pipe(switchMap(_ => this.wipProgressService.getWipContinueProgress())).subscribe(res => {
        if(res.toString().includes("PROCESS COMPLETE:")) {
          console.log("PROCESS COMPLETED !");
          //Setting the Spinner Value to False which stops the Spinner from Spinning.
          this.isSpinning = false;
          //Unsubscribing from the Progress Service once the Progress Finishes.
          this.wipContinueProgress.unsubscribe();

        }
        let wipProgressData=this.extractProgressData
        wipProgressData +=res  
      this.bindProgressData = this.sanitizer.bypassSecurityTrustHtml(wipProgressData);
    });
  }

}
