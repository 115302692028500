<div class="page-layout carded fullwidth">


    <!-- CENTER -->
    <div class="center">


        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT TOOLBAR -->
            <div class="toolbar px-24 py-8">
                <span>WIP Configuration</span>
            </div>
            <!-- / CONTENT TOOLBAR -->

            <!-- CONTENT -->
            <div class="content p-24">
                <form #wipConfigurationForm="ngForm"
                    (ngSubmit)="bulkCreateWipConfiguration(wipConfigurationForm.value)">
                    <div class="wrapper" style="justify-content: space-evenly;">
                        <div *ngFor="let value of allConfig">

                            <div style="width: 100%;">
                                <mat-form-field appearance="outline" style="width: 80%;">
                                    <mat-label>{{value.key}}</mat-label>
                                    <input name="{{value.key}}" matInput ngModel="{{value.value}}"
                                        readonly="{{value.readOnly}}"
                                        [style.color]="value.readOnly ? 'grey' : 'black'" />
                                    <input name="{{value.key}}<>LINK" matInput ngModel="{{value.link}}" hidden />
                                </mat-form-field>
                                <div style="display: inline;">

                                    <button mat-flat-button class="btn" *ngIf="value.link"
                                        style="font-weight: bold; bottom: 8px;">
                                        <a href="{{value.link}}" target="_blank">Open</a>
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content p-24">

                        <div class="pt-24" style="float: left">
                            <!-- <button mat-raised-button class="mat-accent mr-16" (click)="jobdialogRef.close(true)">ok</button> -->
                            <button mat-raised-button color="accent" (click)="refreshWipConfigurationContent()"
                                class="cta-button " type="button">
                                Refresh
                            </button>
                        </div>
                        <div class="pt-24" style="float: right">
                            <!-- <button mat-raised-button class="mat-accent mr-16" (click)="jobdialogRef.close(true)">ok</button> -->
                            <button mat-raised-button color="accent" class="cta-button savebutton" type="button"
                                (click)="openDialog()" style="margin-right: 10px;">
                                &nbsp;&nbsp; Add New Configuration &nbsp;&nbsp;
                            </button>
                            <button mat-raised-button color="accent" class="cta-button savebutton" type="submit">
                                &nbsp;&nbsp; Save &nbsp;&nbsp;
                            </button>
                        </div>
                    </div>
                </form>
                <!-- / CONTENT -->
            </div>
        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>
<!-- HEADER -->