import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import {
  WipConfigService

} from "app/core/_services/index";
import { PopUpComponent } from 'app/newWIP-pop-up/newWIP-pop-up.component';

@Component({
  selector: 'app-wipconfiguration',
  templateUrl: './wipconfiguration.component.html',
  styleUrls: ['./wipconfiguration.component.scss']
})
export class WipconfigurationComponent implements OnInit {
  allConfig: any;
  constructor(
    private WipConfigService: WipConfigService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialog
  ) { }

  readOnlyFields = [
    'jobSheetEmptyCellsRange',
    'jobSheetUnprotectCellsRange',
    'jobSheetJobIDCellRange',
    'jobSheetJobDescriptionCellRange',
    'jobSheetMainTableCellRange',
    'jobSheetSalesTaxAllocationTableCellRange',
    'jobSheetLaborTaxAllocationTableCellRange',
    'jobSheetOtherMiscExpenseTableCellRange',
    'jobSheetQboDataValidationCheckTableCellRange',
    'jobSheetMarkupTable1CellRange',
    'jobSheetMarkupTable2CellRange',
    'jobSheetChangeOrderTableCellRange',
    'jobSheetSummaryTable1CellRange',
    'jobSheetSummaryTable2CellRange',
    'jobSheetSummaryTable3CellRange',
    'jobSheetSummaryTable4CellRange',
    'jobSheetSummaryTable5CellRange',
    'ceImportMargin'
  ];

  ngOnInit(): void {
    this.allConfig = []
    this.getAllWipConfiguration();
    this.dialogRef.afterAllClosed.subscribe(response => {
      this.getAllWipConfiguration();
    });
  }

  openDialog() {
    this.dialogRef.open(PopUpComponent);
  }

  openSnackBar(message, type) {

    if (type === "SUCCESS") {

      const successConfig = new MatSnackBarConfig();
      successConfig.duration = 2000;
      successConfig.verticalPosition = 'top',
        successConfig.panelClass = ['snackbar-success']
      successConfig.horizontalPosition = 'right'

      this.snackBar.open(message, "", successConfig);

    } else {
      const errorConfig = new MatSnackBarConfig();
      errorConfig.duration = 2000;
      errorConfig.verticalPosition = 'top',
        errorConfig.panelClass = ['snackbar-error']
      errorConfig.horizontalPosition = 'right'

      this.snackBar.open(message, "", errorConfig);
    }
  }


  getAllWipConfiguration() {
    try {
      this.allConfig = [];
      let data = {
        id: 'info@armstrongairinc.com'
      }

      this.WipConfigService
        .getAllWipConfig(data).subscribe((response) => {
          if (response) {
            if (this.allConfig.length > 0) {
              this.allConfig = [];
            }
            for (let wip of response) {
              if (this.readOnlyFields.includes(wip.key)) {
                // console.log(wip.key + ' is readonly');
                var data = {

                  key: wip.key,
                  value: wip.value,
                  link: wip.link,
                  readOnly: true

                }
                this.allConfig.push(data);
              } else {
                var data = {

                  key: wip.key,
                  value: wip.value,
                  link: wip.link,
                  readOnly: false

                }
                this.allConfig.push(data);
              }
            }
            console.log("SUCCESSFULLY FETCHED ALL WIP CONFIGURATIONS...");
          }

        });
    } catch (e) {
      console.log('error', e)
      this.openSnackBar("OOPS COULD NOT FETCH WIP CONFIGURATIONS", "ERROR");

    }


  }
  getWipConfiguration() {
    try {
      let data = {
        id: 'info@armstrongairinc.com'
      }
      this.WipConfigService
        .getWipConfig(data).subscribe((response) => {
          if (response) {
            // console.log(response)

          }
        });
    } catch (e) {
      console.log(e)
    }
  }
  addWipConfiguration() {
    try {
      let key = 1
      this.WipConfigService
        .addWipConfig(key).subscribe((response) => {
          if (response.data) {

          }
        });
    } catch (e) {

    }
  }


  bulkCreateWipConfiguration(wipConfigBulkFormData: any) {
    try {

      this.constructAndCreateBulkWipConfigurationData(wipConfigBulkFormData);

    } catch (e) {

    }
  }

  constructAndCreateBulkWipConfigurationData(wipConfigBulkFormData: any[]) {

    try {

      let mainMockDataStructure = [];

      let tempObject = {
        key: "",
        value: "",
        link: ""
      };

      for (const key in wipConfigBulkFormData) {
        if (wipConfigBulkFormData.hasOwnProperty(key)) {

          if (key.substring(key.length - 6) !== "<>LINK") {

            tempObject = {
              key: key,
              value: wipConfigBulkFormData[key],
              link: wipConfigBulkFormData[key + "<>LINK"]
            }

            mainMockDataStructure.push(tempObject);
            tempObject = {
              key: "",
              value: "",
              link: ""
            }
          }

        }
      }

      console.log(mainMockDataStructure);

      this.WipConfigService.bulkCreateWipConfig(mainMockDataStructure).subscribe((response) => {
        // console.log("SUCCESSFULLY BULK UPDATED WIP CONFIGURATION");
        this.openSnackBar("SUCCESSFULLY BULK UPDATED WIP CONFIGURATION!", "SUCCESS");
        this.getAllWipConfiguration();
      },
        (error: HttpErrorResponse) => {
          console.log("OOPS COULD NOT BULK UPDATE WIP CONFIGURATION");
          this.openSnackBar("OOPS COULD NOT BULK UPDATE WIP CONFIGURATION", "ERROR");
          console.log(error);
        });

    } catch (e) {
      console.log(e);
      this.openSnackBar("OOPS COULD NOT BULK UPDATE WIP CONFIGURATION", "ERROR");
    }
  }


  deleteWipConfiguration() {
    try {
      let key = 1
      this.WipConfigService
        .delWipConfig(key).subscribe((response) => {
          if (response.data) {

          }
        });
    } catch (e) {

    }
  }

  refreshWipConfigurationContent() {
    try {

      this.getAllWipConfiguration();

    } catch (e) {
      console.log(e);
    }
  }

}
