import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeWipProcessService {

  private headers = new HttpHeaders()

  constructor(
    private http:HttpClient
  ) { }

   runWipProcess(data){
     console.log('runwipprocess',data)
        this.headers.set('X-TenantID',data.id)
        return this.http.post(environment.wipProcessAPIURL+'/process',data,{responseType:'text'})
    }
    
}
