import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DashboardService } from 'app/core/_services/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  genuineDashboardEmbedURL: SafeUrl;

  isDashboardEmbedURLValid: boolean;

  constructor(
    private DashboardService: DashboardService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {

    this.getDashboardEmbedURL();

  }

  getDashboardEmbedURL(): void {

    try {
      this.DashboardService.getDashboardEmbedURL().subscribe((res) => {
        console.log("Dashboard EMBED URL", res);
        if (res != null && res != undefined && res.length > 0) {

          this.isDashboardEmbedURLValid = true;

          this.genuineDashboardEmbedURL = this.sanitizer.bypassSecurityTrustResourceUrl(res);

        } else {

          this.isDashboardEmbedURLValid = false;

        }
      });
    } catch (e) {
      console.log("Exception at Getting Dashboard URL", e);
      this.isDashboardEmbedURLValid = false;
    }

  }

}
