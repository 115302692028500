import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FuseSharedModule } from '@fuse/shared.module';
import {MatGridListModule} from '@angular/material/grid-list'
import { ChartofaccountsComponent} from './chartofaccounts.component';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FuseDemoModule, FuseSidebarModule } from '@fuse/components';
import { MatDividerModule } from '@angular/material/divider';
import { MatOptionModule } from '@angular/material/core';
import { EditableComponent } from '../editable/editable.component';
import { ViewModeDirective } from '../editable/view-mode.directive';
import { EditModeDirective } from '../editable/edit-mode.directive';
import { FocusableDirective } from '../editable/focusable.directive';
import { EditableOnEnterDirective } from '../editable/edit-on-enter.directive';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {AuthGuard} from '../../core/_guards/auth.guard'

const routes = [
    {
        path     : 'chartofaccount',
        component: ChartofaccountsComponent,
        canActivate: [AuthGuard],
        data: {title: 'Charts-of-Accout',
        metaDesc :'Welcome to PowerWIP Chartsofaccount'
            }
    }
];

@NgModule({
    declarations: [
        ChartofaccountsComponent,EditableComponent,ViewModeDirective,
        EditModeDirective,  FocusableDirective,
        EditableOnEnterDirective
        
    ],
    imports     : [
        RouterModule.forChild(routes),

       

       MatButtonModule,
        MatIconModule,
        MatOptionModule,
        
       MatDividerModule,
        MatTableModule,
        MatFormFieldModule,
        MatDialogModule,
        MatSelectModule,
        MatCheckboxModule,
        FuseSidebarModule,
        FuseSharedModule,
        FuseDemoModule,
        MatGridListModule,
        FlexLayoutModule,
        MatNativeDateModule, 
        MatPaginatorModule,
        MatSortModule,
        MatRippleModule,
        ScrollingModule

    ],
    exports     : [
        ChartofaccountsComponent
    ],
 
})

export class ChartOfAccountsModule
{
    
}
