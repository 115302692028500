import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';  
import { FlexLayoutModule } from '@angular/flex-layout';
import { DialogmodalComponent } from './dialogmodal.component';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FuseDemoModule, FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatInputModule } from '@angular/material/input';
import { ChartOfAccountsModule } from '../chartofaccounts/chartofaccounts.module';
import { ChartofaccountsComponent } from '../chartofaccounts/chartofaccounts.component';






@NgModule({
    declarations: [
        DialogmodalComponent
    ],
    imports: [
       
        MatInputModule,
        FlexLayoutModule,
        CommonModule,
        BrowserModule,
        MatSelectModule,
       MatButtonModule,
       MatIconModule,
       MatOptionModule,
      MatDividerModule,
       MatTableModule,
       MatFormFieldModule,
       MatDialogModule,
       MatCheckboxModule,
       FuseSidebarModule,
       FuseSharedModule,
       FuseDemoModule,
       
    
    ],
    entryComponents: [
        DialogmodalComponent
    ],
    exports:[
        MatFormFieldModule,
        MatButtonModule,
        MatSelectModule,
        MatInputModule,
        

    ]
})
export class DialogModalModule
{
    
}
