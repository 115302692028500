import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WipConfigService {

  companyID: string;

  private headers = new HttpHeaders()

  constructor(private http: HttpClient) {
    this.companyID = sessionStorage.getItem("powerwip-companyIdentifier");
  }

  // getCompanyIdentifier() {
  //   return this.http.get<any>(environment.wipProcessAPIURL + "/company", { responseType: 'json' })
  // }

  getAllWipConfig(id) {
    this.headers.set('X-TenantID', this.companyID);
    return this.http.get<any>(environment.baseAPIUrl + '/api/wipConfiguration', {
      headers: {
        'X-TenantID': this.companyID
      }
    })
  }
  getWipConfig(data) {
    console.log('servicedata', data.id)
    this.headers.set('X-TenantID', data.id)
    //console.log(this.)
    return this.http.get<any>(environment.baseAPIUrl + `/api/wipConfiguration/${data.key}`, { headers: this.headers })
  }
  addWipConfig(data) {
    // this.headers.set('X-TenantID', data.id)
    return this.http.post<any>(environment.baseAPIUrl + '/api/wipConfiguration', data, {
      headers: {
        'X-TenantID': this.companyID
      }
    })
  }
  delWipConfig(data) {
    this.headers.set('X-TenantID', this.companyID)
    return this.http.delete<any>(environment.baseAPIUrl + `/api/wipConfiguration/${data.key}`, { headers: this.headers })
  }
  updateWipConfig(data) {
    this.headers.set('X-TenantID', this.companyID)
    return this.http.put<any>(environment.baseAPIUrl + `/api/wipConfiguration/${data.key}`, data.wipData, { headers: this.headers })
  }
  bulkCreateWipConfig(data) {
    return this.http.post<any>(environment.baseAPIUrl + `/api/wipConfiguration/bulk`, data, {
      headers: {
        'X-TenantID': this.companyID
      }
    })
  }
}
