<h2>New WIP Configuration</h2>

<form [formGroup]="addNewWIPForm" (ngSubmit)="createWipConfiguration()">

    <div class="wrapper" style="justify-content: space-evenly;">

        <div style="width: 100%;">

            <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Key</mat-label>
                <input name="key" matInput formControlName="key" />
            </mat-form-field>

            <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Value</mat-label>
                <input name="value" matInput formControlName="value" />
            </mat-form-field>

            <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Configuration Type</mat-label>
                <select name="type" formControlName="type" matNativeControl required>
                    <option value="SHEET">SHEET</option>
                    <option value="FOLDER">FOLDER</option>
                    <option value="OTHER">OTHER</option>
                </select>
            </mat-form-field>

            <!-- <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Link</mat-label>
                <input name="link" matInput formControlName="link" />
            </mat-form-field> -->

        </div>

        <div class="content">

            <div class="pt-24" style="float: left">
                <button mat-raised-button color="accent" class="cta-button " type="button"
                    (click)="resetCreateWipConfigurationForm()">
                    Clear Fields
                </button>
            </div>
            <div class="pt-24" style="float: right">
                <button mat-raised-button color="accent" class="cta-button savebutton"
                    [disabled]="addNewWIPForm.invalid" mat-dialog-close type="submit">
                    &nbsp;&nbsp; Save &nbsp;&nbsp;
                </button>
            </div>
        </div>

    </div>

</form>