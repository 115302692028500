import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WipProgressService {

  constructor(private http: HttpClient) { }
  getConfig() {
    return this.http.get(environment.wipProcessAPIURL + '/progress', { responseType: 'text' });
  }
  getWipContinueProgress() {
    return this.http.get(environment.wipContinueAPIURL + '/progress', { responseType: 'text' });
  }
  resetProgressMessage() {
    return this.http.get(environment.wipProcessAPIURL + '/resetProgress', { responseType: 'text' })
  }
  resetWipContinueProgressMessage(){
    return this.http.get(environment.wipContinueAPIURL + '/resetProgress', { responseType: 'text' })
  
  }
}
