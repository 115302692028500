import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { Auth } from 'aws-amplify';
import { LoginService } from 'app/core/_services';
import { observable, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';


@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class LoginComponent implements OnInit {
    name: string;
    loginForm: FormGroup;
    auth_uri: string;
    tokenObserver;
    sessionObj: any;
    // accessSession: any;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private router: Router,
        private loginService: LoginService,
        private http: HttpClient
        // private activatedRoute: ActivatedRoute

    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
        

        try {
            this.loginService.startAuth().subscribe((authuri) => {
                console.log(authuri, "START AUTH RESPONSE");
                this.auth_uri = authuri;
            });

            this.loginService.getAccessSession().subscribe((userSession) => {
                this.sessionObj = JSON.parse(userSession);
                console.log(this.sessionObj, "USER ACCESS SESSION VALUE");
                if (this.sessionObj != null && this.sessionObj != undefined) {
                    console.log('User Session is not NULL or UNDEFINED !')
                    sessionStorage.setItem("powerwip-token", this.sessionObj.token);
                    sessionStorage.setItem("powerwip-username", this.sessionObj.username);
                    sessionStorage.setItem("powerwip-companyName", this.sessionObj.companyName);
                    sessionStorage.setItem("powerwip-companyIdentifier", this.sessionObj.companyIdentifier);
                    this.router.navigate(['/dashboard']);
                } else {
                    console.log('CLEARED SESSION.. User Session is NULL OR UNDEFINED..')
                    sessionStorage.clear();
                }
            });
        } catch (e) {
            console.log(e, "Error Caught in login.component.ts ngOnInit() ");
        }

    }

    login() {

        window.location.href = this.auth_uri;

        //COGNITO WAY BELOW >>
        // Auth.federatedSignIn({ customProvider: 'Intuit' }).then(result => {

        //     console.log('login result', result)

        //     Auth.currentSession().then(userInfo => {
        //         sessionStorage.setItem("ACCESS_TOKEN", userInfo.getAccessToken().getJwtToken())
        //         sessionStorage.setItem("ID_TOKEN", userInfo.getIdToken().getJwtToken())
        //         sessionStorage.setItem("REFRESH_TOKEN", userInfo.getRefreshToken().getToken())


        //     })
        // }).catch(e => {
        //     console.log(e);
        // });


    }
}
