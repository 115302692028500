import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRoutes, RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';

import { ChartOfAccountsModule } from './main/chartofaccounts/chartofaccounts.module';
import { MatTableModule } from '@angular/material/table';
import { DialogmodalComponent } from './main/dialogmodal/dialogmodal.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { DialogModalModule } from './main/dialogmodal/dialogmodal.module';
import { HomeComponent } from './main/home/home.component';
import { HomeModule } from './main/home/home.module';
import { WipconfigurationComponent } from './main/wipconfiguration/wipconfiguration.component';
import { wipconfigModule } from './main/wipconfiguration/wipconfiguration.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { DashboardComponent } from './main/dashboard/dashboard.component';
import { DashboardModule } from './main/dashboard/dashoard.module';

import { MatGridListModule } from '@angular/material/grid-list';
import { LoginModule } from './main/login/login.module';
import { AuthGuard } from './core/_guards/auth.guard';
import { MatDialogModule } from '@angular/material/dialog';
import { PopUpComponent } from './newWIP-pop-up/newWIP-pop-up.component';
import { LoginService } from './core/_services';
import { MatRadioModule } from '@angular/material/radio';


const appRoutes: Routes = [
    // {
    //     path      : 'home',
    //     redirectTo: 'home',
    //     pathMatch:"full",
    //     //canActivate: [AuthGuard]

    // },
    // {
    //     path      : 'chartofaccount',
    //     redirectTo: 'chartofaccount',
    //     pathMatch:"prefix",
    //    // canActivate: [AuthGuard]
    // },
    // {
    //     path      : 'wipconfiguration',
    //     redirectTo: 'wipconfiguration',
    //     pathMatch:"prefix",
    //     //canActivate: [AuthGuard]
    // },
    // {
    //     path      : 'dashboard',
    //     redirectTo: 'dashboard',
    //     pathMatch:"prefix",
    //    // canActivate: [AuthGuard]
    // },
    //  {
    //       path      : '**',
    //     redirectTo: 'login',
    //     pathMatch:"full",

    // }
];

@NgModule({
    declarations: [
        AppComponent,
        PopUpComponent,

    ],

    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy', paramsInheritanceStrategy: 'always' }),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatTableModule,
        MatFormFieldModule,
        MatOptionModule,
        MatSelectModule,
        MatGridListModule,
        MatFormFieldModule,
        MatDialogModule,
        MatRadioModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,


        // App modules
        LayoutModule,

        ChartOfAccountsModule,
        HomeModule,
        wipconfigModule,
        DashboardModule,
        DialogModalModule,
        LoginModule
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        AuthGuard,
        LoginService
    ]
})
export class AppModule {

}
