<div  class="page-layout simple   inner-scroll">

    <!-- HEADER -->
    <div class=" p-16 p-sm-24" fxLayout="column" fxLayoutAlign="start"
         fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">

        <!-- APP TITLE -->
        <div fxlayout.xs="coloumn" >
            <div  class="batchdiv" fxLayout="row" fxLayoutAlign="start center">
                
                <mat-form-field class="batchbutton" appearance="fill">
                    <mat-label>Batch Actions </mat-label>
                    <mat-select>
                      <mat-option (click)=batchEdit() >
                       View / Edit
                      </mat-option>
                      <mat-option (click)=moveUp()>
                        Move up
                      </mat-option>
                      <mat-option (click)=moveDown()>
                       Move down
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>
          </div>
        
        <!-- / APP TITLE -->

        <!-- SEARCH -->

      
            <div fxlayout.xs="coloumn" >

                <div  fxFlex fxLayout="row" fxLayoutAlign="start end">
                    <button mat-raised-button color="accent"  aria-label="manage-category" (click)=managecat() class="keydown" >
                        Manage Category
                        </button>
                </div>

            </div>

       
        <!-- / SEARCH -->

    </div>
    <!-- / HEADER -->
   
    <div class="table">
        <mat-table #table  [dataSource]="dataSource" class="mat-elevation-z8">       
            <ng-container matColumnDef="select">
              <mat-header-cell *matHeaderCellDef >
                <mat-checkbox (change)="$event ? masterToggle() : null"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()"
                              >
                </mat-checkbox>
              </mat-header-cell>
              <mat-cell *matCellDef="let row" >
                <mat-checkbox *ngIf="row" (click)="$event.stopPropagation()"
                              (change)="$event ? selection.toggle(row) : null"
                              [checked]="selection.isSelected(row)"
                             >
                </mat-checkbox>
              </mat-cell>
            </ng-container>
        
          
            <!-- Position Column -->
            
            <ng-container matColumnDef="category">
              <mat-header-cell *matHeaderCellDef>CATEGORY</mat-header-cell>
              <mat-cell *matCellDef="let element" > 
              
               <span *ngIf="element"> {{element.category}} </span> 
              
             </mat-cell>
            </ng-container>
          
            <ng-container  matColumnDef="displayName">
                <mat-header-cell *matHeaderCellDef> DISPLAY NAME </mat-header-cell> 
                <mat-cell *matCellDef="let element" >
                <span *ngIf="element">	
                  {{element.displayName}} 
                </span> 
                </mat-cell>
                
              </ng-container>
            
            <!-- Name Column -->
            <ng-container matColumnDef="qboName">
              <mat-header-cell *matHeaderCellDef > QBO NAME </mat-header-cell> 
              <mat-cell *matCellDef="let element" > <span *ngIf="element">{{element.qboName}} </span></mat-cell>
            </ng-container>
          
            <!-- Weight Column -->
            <ng-container matColumnDef="glCode">
              <mat-header-cell *matHeaderCellDef> GLCODE </mat-header-cell>
              <mat-cell *matCellDef="let element" ><span *ngIf="element">{{element.glCode}}</span> </mat-cell>
            </ng-container>
          
            <!-- Symbol Column -->
            <ng-container matColumnDef="option">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let element" ><a class="pointer" (click)=viewEdit(element) *ngIf="element">View / Edit</a> </mat-cell>
            </ng-container>
          
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"
                (click)="selection.toggle(row)">
            </mat-row>
        </mat-table>    
             
        </div>   
</div>

