import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import Amplify, { Auth, Hub } from 'aws-amplify';
//import awsconfig from './aws-exports';
import awsconfig from './app/core/_config/awsconfig.json'
import awsauth from './app/core/_config/awsauth.json'


Amplify.configure(awsconfig);
Auth.configure({oauth:awsauth})
 Hub.listen('auth', ({ payload: { event, data } }) => {

    switch (event) {

        case 'signIn':

            console.log('sign in payload', data)

            // this.setState({ user: data})

            break

        case 'signOut':

            console.log('sign out')

            // this.setState({ user: null })

            break

        default: console.log("DEFAULT");

    }

})

if ( environment.production )
{
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
                        .catch(err => console.error(err));
