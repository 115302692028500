<div *ngIf="IsManageCategory" >
<h1 matDialogTitle>Manage category</h1>
<div mat-dialog-content >
    <div fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="1 0 auto">
        <div fxFlex="100" class="addtional-details">
            <div  class="page-layout simple   inner-scroll">

                
                <div class=" p-16 p-sm-24" fxLayout="column" fxLayoutAlign="start"
                     fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">
            
                    
                    <div fxLayout="row" fxLayoutAlign="start center">
            
                        
            
                        <div  class="catinput" fxLayout="row" fxLayoutAlign="start none">
                            
                            <mat-form-field appearance="outline" fxFlex="250" class="pr-4">
                                <mat-label>Category</mat-label>
                                <input matInput  >
                               
                            </mat-form-field>
                        </div>
            
                    </div>
                   
                    <div class="ml-sm-32" fxLayout="row" fxLayoutAlign="end start" fxFlex>
            
                        <div >
            
                            <div  fxFlex fxLayout="row" class="addcat" fxLayoutAlign="start end">
                                <button mat-raised-button color="accent"   aria-label="manage-category">
                                    Add New
                                    </button>
                            </div>
            
                        </div>
            
                    </div>
                   
                    
                </div>
                               
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
            
                        
            
                <div  class="batchdiv" fxLayout="row" fxLayoutAlign="start center">
                    
                    <mat-form-field class="batchbutton" appearance="fill">
                        <mat-label>Batch Actions </mat-label>
                        <mat-select>
                          <mat-option >
                            View / Edit
                          </mat-option>
                          <mat-option >
                            Move up
                          </mat-option>
                          <mat-option>
                           Move down
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
               
            </div>
            <div class="table"  >
                <mat-table #table  [dataSource]="dataSource" class="mat-elevation-z8">
                
                    <ng-container matColumnDef="select">
                      <mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                                      [checked]="selection.hasValue() && isAllSelected()"
                                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                                      >
                        </mat-checkbox>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                      (change)="$event ? selection.toggle(row) : null"
                                      [checked]="selection.isSelected(row)"
                                     >
                        </mat-checkbox>
                      </mat-cell>
                    </ng-container>
                                  
                    <!-- Weight Column -->
                    <ng-container matColumnDef="category">
                      <mat-header-cell *matHeaderCellDef> Category </mat-header-cell>
                      <mat-cell *matCellDef="let element"><span>{{element.category}}</span> </mat-cell>
                    </ng-container>
                  
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="option">
                      <mat-header-cell *matHeaderCellDef></mat-header-cell>
                                <mat-cell *matCellDef="let element"><a href="">Delete</a> </mat-cell>
                    </ng-container>
                  
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"
                        (click)="selection.toggle(row)">
                    </mat-row>
                </mat-table>
                  
                </div>
        </div>
    </div>
</div>
<div mat-dialog-actions class="pt-24" style="float: left">
    <!-- <button mat-raised-button class="mat-accent mr-16" (click)="jobdialogRef.close(true)">ok</button> -->
    <button mat-raised-button color="accent" (click)="manageCatDialogRef.close(true)"  class="cta-button" >
        Cancel
    </button>
</div>
<div mat-dialog-actions class="pt-24" style="float: right">
    <!-- <button mat-raised-button class="mat-accent mr-16" (click)="jobdialogRef.close(true)">ok</button> -->
    <button mat-raised-button color="accent" (click)="manageCatDialogRef.close(true)" class="cta-button savebutton">
       Save and Close
    </button>
</div>

</div>

<div *ngIf="IsViewEdit" >
    <h1 matDialogTitle>GL Code Hierarchy</h1>
    <div mat-dialog-content >
        <div fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="1 0 auto">
            <div fxFlex="100" class="addtional-details">
                <div  class="page-layout simple   inner-scroll">
    
                    <div class="table"  >
                        <mat-table #table  [dataSource]="dataSourceSingleGL" class="mat-elevation-z8">
                        
                            
                                          
                            <!-- Weight Column -->
                            <ng-container matColumnDef="qboName">
                              <mat-header-cell *matHeaderCellDef> QBO Name  </mat-header-cell>
                              <mat-cell *matCellDef="let element"><span>{{element.qboName}}</span> </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="displayName">
                                <mat-header-cell *matHeaderCellDef> Display Name  </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <input matInput placeholder="Name" [value]="element.displayName" > </mat-cell>
                              </ng-container>
                              
                            <!-- Symbol Column -->
                            <ng-container matColumnDef="glCode">
                              <mat-header-cell *matHeaderCellDef>GLCode</mat-header-cell>
                                        <mat-cell *matCellDef="let element">{{element.glCode}} </mat-cell>
                            </ng-container>
                          
                            <mat-header-row *matHeaderRowDef="displayedColumnsSingleGL"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumnsSingleGL;"
                                (click)="selection.toggle(row)">
                            </mat-row>
                        </mat-table>
                          
                        </div>
                    
                                   
                </div>
                <div  class="page-layout simple   inner-scroll">
    
                    
                    <div class=" p-16 p-sm-24" fxLayout="column" fxLayoutAlign="start"
                         fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">
                
                        
                        <div fxLayout="row" fxLayoutAlign="start center">
                
                            
                
                            <div class="catinput2"  fxLayout="row" fxLayoutAlign="start none">
                                
                                
                                <mat-form-field [style.width.px]=330 >
                                    <mat-label>Select Category</mat-label>
                                    <mat-select>
                                       <input (keyup)="onKeyCat($event.target.value)" placeholder="Enter Category" style="height: 50px;"> 
                                      
                                      <mat-option *ngFor="let category of categories" [value]="category">{{category}}</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                            </div>
                
                        </div>
                       <br>
                        <div class="ml-sm-32 mat-card" fxLayout="column" fxLayoutAlign="end end" fxFlex>
                            
                            
                                <div  fxFlex fxLayout="column" class="addcat3" fxLayoutAlign="end end">
                                    <mat-checkbox class="example-margin">Is a child GL Code of</mat-checkbox>
                                    
                                </div>
                          <div class="drop-down-m-top"></div>
                            <mat-form-field [style.width.px]=330 >
                                <mat-label>Select GLCode</mat-label>
                                <mat-select>
                                   <input (keyup)="onKey($event.target.value)" placeholder="Enter glcode" style="height: 50px;"> 
                                  
                                  <mat-option *ngFor="let glcode of glCodes" [value]="glcode">{{glcode}}</mat-option>
                                </mat-select>
                              </mat-form-field>
                
                        </div>
                       
                        
                    </div>
                                   
                </div>
                <!-- <mat-form-field [style.width.px]=330 style="float: right;">
                    <mat-label>Select GLCode</mat-label>
                    <mat-select>
                       <input (keyup)="onKey($event.target.value)" placeholder="Enter glcode" style="height: 50px;"> 
                      
                      <mat-option *ngFor="let glcode of glCodes" [value]="glcode">{{glcode}}</mat-option>
                    </mat-select>
                  </mat-form-field> -->
                <!-- <div>
                <form class="parent-glcode-form">
                    <mat-form-field class="parent-glcode-full-width">
                      <mat-label>Assignee</mat-label>
                      <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto">
                      <mat-autocomplete  #auto="matAutocomplete" [displayWith]="displayFn">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                          {{option.glCode}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </form>
                </div> -->
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="pt-24" style="float: left">
        <!-- <button mat-raised-button class="mat-accent mr-16" (click)="jobdialogRef.close(true)">ok</button> -->
        <button mat-raised-button color="accent" (click)="viewEditDialogRef.close(true)" class="cta-button" >
            Cancel
        </button>
    </div>
    <div mat-dialog-actions class="pt-24" style="float: right">
        <!-- <button mat-raised-button class="mat-accent mr-16" (click)="jobdialogRef.close(true)">ok</button> -->
        <button mat-raised-button color="accent" (click)="viewEditDialogRef.close(true)" class="cta-button savebutton">
           Save and Close
        </button>
    </div>
    
    </div>
   
   
   
    <div *ngIf="IsBatchEdit" >
        <h1 matDialogTitle>GL Code Hierarchy -Batch Action</h1>
        <div mat-dialog-content >
            <div fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="1 0 auto">
                <div fxFlex="100" class="addtional-details">
                    <div  class="page-layout simple   inner-scroll">
        
                        <div class="table"  >
                            <mat-table #table  [dataSource]="datasourceBatchEditGL" class="mat-elevation-z8">
                            
                                
                                              
                                <!-- Weight Column -->
                                <ng-container matColumnDef="qboName">
                                  <mat-header-cell *matHeaderCellDef> QBO Name  </mat-header-cell>
                                  <mat-cell *matCellDef="let element"><span>{{element.qboName}}</span> </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="displayName">
                                    <mat-header-cell *matHeaderCellDef> Display Name  </mat-header-cell>
                                    <mat-cell *matCellDef="let element">{{element.displayName}}
                                        </mat-cell>
                                  </ng-container>
                                  
                                <!-- Symbol Column -->
                                <ng-container matColumnDef="glCode">
                                  <mat-header-cell *matHeaderCellDef>GLCode</mat-header-cell>
                                            <mat-cell *matCellDef="let element">{{element.glCode}} </mat-cell>
                                </ng-container>
                              
                                <mat-header-row *matHeaderRowDef="displayedColumnsbatchEditGL"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumnsbatchEditGL;"
                                    (click)="selection.toggle(row)">
                                </mat-row>
                            </mat-table>
                              
                            </div>
                        
                                       
                    </div>
                    <div  class="page-layout simple   inner-scroll">
        
                        
                        <div class=" p-16 p-sm-24" fxLayout="column" fxLayoutAlign="start"
                             fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">
                    
                            
                            <div fxLayout="row" fxLayoutAlign="start center">
                    
                                
                    
                                <div class="catinput2"  fxLayout="row" fxLayoutAlign="start none">
                                    
                                    
                                    <mat-form-field [style.width.px]=330 >
                                        <mat-label>Select Category</mat-label>
                                        <mat-select>
                                           <input (keyup)="onKeyCat($event.target.value)" placeholder="Enter Category" style="height: 50px;"> 
                                          
                                          <mat-option *ngFor="let category of categories" [value]="category">{{category}}</mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                </div>
                    
                            </div>
                           <br>
                            <div class="ml-sm-32 mat-card" fxLayout="column" fxLayoutAlign="end end" fxFlex>
                                
                                
                                    <div  fxFlex fxLayout="column" class="addcat3" fxLayoutAlign="end end">
                                        <mat-checkbox class="example-margin">Is a child GL Code of</mat-checkbox>
                                        
                                    </div>
                                    <div class="drop-down-m-top"></div>
                                <mat-form-field [style.width.px]=330 >
                                    <mat-label>Select GLCode</mat-label>
                                    <mat-select>
                                       <input (keyup)="onKey($event.target.value)" placeholder="Enter glcode" style="height: 50px;"> 
                                      
                                      <mat-option *ngFor="let glcode of glCodes" [value]="glcode">{{glcode}}</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                    
                            </div>
                           
                            
                        </div>
                                       
                    </div>
                    <!-- <mat-form-field [style.width.px]=330 style="float: right;">
                        <mat-label>Select GLCode</mat-label>
                        <mat-select>
                           <input (keyup)="onKey($event.target.value)" placeholder="Enter glcode" style="height: 50px;"> 
                          
                          <mat-option *ngFor="let glcode of glCodes" [value]="glcode">{{glcode}}</mat-option>
                        </mat-select>
                      </mat-form-field> -->
                    <!-- <div>
                    <form class="parent-glcode-form">
                        <mat-form-field class="parent-glcode-full-width">
                          <mat-label>Assignee</mat-label>
                          <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto">
                          <mat-autocomplete  #auto="matAutocomplete" [displayWith]="displayFn">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                              {{option.glCode}}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </form>
                    </div> -->
                </div>
            </div>
        </div>
        <div mat-dialog-actions class="pt-24" style="float: left">
            <!-- <button mat-raised-button class="mat-accent mr-16" (click)="jobdialogRef.close(true)">ok</button> -->
            <button mat-raised-button color="accent" (click)="viewEditDialogRef.close(true)" class="cta-button" >
                Cancel
            </button>
        </div>
        <div mat-dialog-actions class="pt-24" style="float: right">
            <!-- <button mat-raised-button class="mat-accent mr-16" (click)="jobdialogRef.close(true)">ok</button> -->
            <button mat-raised-button color="accent" (click)="viewEditDialogRef.close(true)" class="cta-button savebutton">
               Save and Close
            </button>
        </div>
        
        </div>