import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { MatGridListModule } from '@angular/material/grid-list'

import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FuseDemoModule, FuseSidebarModule } from '@fuse/components';
import { MatDividerModule } from '@angular/material/divider';
import { MatOptionModule } from '@angular/material/core';
import { WipconfigurationComponent } from './wipconfiguration.component';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { AuthGuard } from '../../core/_guards/auth.guard';


const routes = [
    {
        path: 'wipconfiguration',
        component: WipconfigurationComponent,
        canActivate: [AuthGuard],
        data: {
            title: 'WIPConfiguration',
            metaDesc: 'Welcome to PowerWIP wipConfigPage'
        }
    }
];

@NgModule({
    declarations: [
        WipconfigurationComponent,

    ],
    imports: [
        RouterModule.forChild(routes),


        MatCardModule,
        MatButtonModule,
        MatIconModule,
        MatOptionModule,
        MatInputModule,
        MatDividerModule,
        MatTableModule,
        MatFormFieldModule,
        MatDialogModule,
        MatSelectModule,
        MatCheckboxModule,
        FuseSidebarModule,
        FuseSharedModule,
        FuseDemoModule,
        MatGridListModule,
        MatSnackBarModule

    ],
    exports: [
        WipconfigurationComponent,
        MatInputModule,
        MatFormFieldModule
    ]
})

export class wipconfigModule {
}
