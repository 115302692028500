<!-- <mat-form-field appearance="standard"
                [ngClass]="jpCustomFormFieldClass">
  <mat-label>{{label}}</mat-label>
  <input matInput
         (click)="_openDatepickerOnClick(datepicker)"
         (keydown.arrowdown)="_openDatepickerOnClick(datepicker)"
         (keydown.enter)="_openDatepickerOnClick(datepicker)"
         [formControl]="_inputCtrl"
         [matDatepicker]="datepicker"
         [max]="_max"
         [min]="_min"
         readonly>
</mat-form-field>
<mat-datepicker #datepicker
                (closed)="_takeFocusAway(datepicker)"
                (yearSelected)="_yearSelectedHandler($event,datepicker)"
                [touchUi]="touchUi"
                panelClass="jp-year-picker"
                startView="multi-year">
</mat-datepicker> -->
<!-- <div style="margin-top: 5%;"> -->

<div class="page-layout carded fullwidth">


  <!-- CENTER -->
  <div class="center">


    <!-- CONTENT CARD -->
    <div class="content-card">

      <!-- CONTENT TOOLBAR -->
      <div class="toolbar px-24 py-8">
        <span>Run WIP Process</span>
      </div>
      <!-- / CONTENT TOOLBAR -->

      <!-- CONTENT -->
      <div class="content p-24">

        <form name="wipProcessForm" [formGroup]="wipProcessForm" novalidate>

          <div class=" pt-24" class="batchdiv">
            <div fxlayout.xs="coloumn">
              <mat-form-field class="batchbutton" color="accent" appearance="fill">
                <mat-label>Year</mat-label>
                <mat-select formControlName="year">
                  <mat-option *ngFor="let year of yearsList" [value]='year'>
                    {{year}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="wipProcessForm.get('year').hasError('required')">
                  select year to run the process
                </mat-error>

              </mat-form-field>
              <mat-form-field class="batchbutton" color="accent" appearance="fill">
                <mat-label>Completed Period: </mat-label>
                <input matInput formControlName="completedPeriod" [matDatepicker]="picker2" [value]="date.value" />
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2 color="primary"></mat-datepicker>
                <mat-error *ngIf="wipProcessForm.get('completedPeriod').hasError('required')">
                  select completedPeriod to run the process
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div>
           
            <button mat-raised-button color="accent" class="cta-button keydown" style="background-color:#d1e02b;" (click)="wipProcessSubmit(true)"
              [disabled]="wipProcessForm.invalid">
              Refresh WIP Data Only
            </button>
            <br>
            <button mat-raised-button class="cta-button  keydown" style="background-color:#d1e02b;" (click)="wipProcessSubmit(false)"
              [disabled]="isProd ? wipProcessForm.invalid:!isProd">
              Run Full Process
            </button>
          </div>

          <div *ngIf="bindProgressData" style="background-color : rgb(224, 224, 224)">

            <div style="display: flex;align-items: center;">
              <mat-spinner *ngIf="isSpinning" strokeWidth="2" [diameter]="30"></mat-spinner>
              <h2 style="margin-left: 10px">Progress Logs :</h2>
            </div>

            <hr />

            <div [innerHTML]="bindProgressData">
            </div>

          </div>
        </form>
      </div>

      <!-- / CONTENT -->

    </div>
    <!-- / CONTENT CARD -->

  </div>
  <!-- / CENTER -->

</div>