import { Component,Inject ,OnInit } from '@angular/core';

import {​​ DataSource }​​ from '@angular/cdk/table';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith,filter} from 'rxjs/operators';

export interface ManageCatElement {
  category: string;
}
export interface singleGLCode {
  qboName: string;
  DisplayName: string;
  GLCode: string;
}
export interface batchGLCode {
  qboName: string;
  displayName: string;
  glCode: string;
}
// export interface ParentGLCode {
//   glCode: string;
// }

const ELEMENT_DATA: ManageCatElement[] = [
  {  category: 'unassigned' },
  {  category: 'unassigned' },
  { category: 'unassigned'},
  {  category: 'unassigned' },
  {  category: 'unassigned' },
  { category: 'unassigned'},
  {  category: 'unassigned' },
  {  category: 'unassigned' },
  { category: 'unassigned'},
  {  category: 'unassigned' },
  {  category: 'unassigned' },
  { category: 'unassigned'},
  {  category: 'unassigned' },
  {  category: 'unassigned' },
  { category: 'unassigned'},
];



@Component({
  selector: 'app-dialogmodal',
  templateUrl: './dialogmodal.component.html',
  styleUrls: ['./dialogmodal.component.scss']
})


export class DialogmodalComponent  {
  
  /**
     * Constructor
     *
     * @param {MatDialogRef<DialogmodalComponent>} manageCatDialogRef
     * @param {MatDialogRef<DialogmodalComponent>} viewEditDialogRef
     * @param {MatDialogRef<DialogmodalComponent>} batchEditDialogRef
     */
  glCode: string[] = ['5000','5001','5002']
  category:string[]=['category1','category2','category3']
  ELEMENT_DATA1: singleGLCode[] 
ELEMENT_DATA2: batchGLCode[]=[]

  glCodes=this.glCode;
categories=this.category
onKey(value) { 
  this.glCodes = this.search(value);
  }
  onKeyCat(value){
this.categories=this.searchcat(value)
  }
  search(value: string) { 
    let filter = value.toLowerCase();
    return this.glCode.filter(option => option.toLowerCase().startsWith(filter));
  }
  searchcat(value: string) { 
    let filter = value.toLowerCase();
    return this.category.filter(option => option.toLowerCase().startsWith(filter));
  }
  IsManageCategory:boolean;
  IsViewEdit:boolean;
  IsBatchEdit:boolean;
 
  
  displayedColumns: string[] = ['select',  'category','option'];
  displayedColumnsSingleGL: string[] = ['qboName',  'displayName','glCode'];
  displayedColumnsbatchEditGL: string[] = ['qboName',  'displayName','glCode'];
  datasourceBatchEditGL;
  
  dataSource = new MatTableDataSource<ManageCatElement>(ELEMENT_DATA);
  dataSourceSingleGL ;
  
  selection = new SelectionModel<ManageCatElement>(true, []);
 
  isAllSelected() {

    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: ManageCatElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    
  
  }

  constructor( 
    
    public manageCatDialogRef: MatDialogRef<DialogmodalComponent>,
    public viewEditDialogRef: MatDialogRef<DialogmodalComponent>,
    public batchEditDialogRef: MatDialogRef<DialogmodalComponent>,
    
    
    @Inject(MAT_DIALOG_DATA) public data: any) 
    { 
if (this.data.key==="category"){
this.IsManageCategory=true
this.IsViewEdit=false
this.IsBatchEdit=false

}
if (this.data.key==="viewedit"){
  this.IsManageCategory=false
  this.IsViewEdit=true
  this.IsBatchEdit=false
  console.log(data.selectedData)
  this.ELEMENT_DATA1 = data.selectedData;
    this.dataSourceSingleGL= new MatTableDataSource<singleGLCode>(this.ELEMENT_DATA1);
  
  }
  if (this.data.key==="batchedit"){
    this.IsManageCategory=false 
    this.IsViewEdit=false
    this.IsBatchEdit=true
    console.log(data.selectedData)
    this.ELEMENT_DATA2 = data.selectedData;
    this.datasourceBatchEditGL= new MatTableDataSource<batchGLCode>(this.ELEMENT_DATA2);

   
    
    
    }
    
  }

  
  ngOnInit(): void {
    
  }
  closedialog(){
    this.manageCatDialogRef.close()
  }
 
}
