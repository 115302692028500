import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FuseDemoModule, FuseSidebarModule } from '@fuse/components';
import { MatDividerModule } from '@angular/material/divider';
import { MatOptionModule } from '@angular/material/core';
import { HomeComponent } from './home.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { DatePipe } from '@angular/common';
import { AuthGuard } from '../../core/_guards/auth.guard';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
//import { NgYasYearPickerModule } from 'ngy-year-picker/ngy-year-picker';

const routes = [
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard],
        data: {
            title: 'Home',
            metaDesc: 'Welcome to PowerWIP home'
        }
    }
];

@NgModule({
    declarations: [
        HomeComponent,
    ],
    providers: [DatePipe],
    imports: [
        RouterModule.forChild(routes),


        // NgYasYearPickerModule, 
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatOptionModule,
        MatDatepickerModule,
        MatDividerModule,
        MatTableModule,
        MatInputModule,
        MatFormFieldModule,
        MatDialogModule,
        MatSelectModule,
        MatCheckboxModule,
        FuseSidebarModule,
        FuseSharedModule,
        FuseDemoModule,
        MatProgressSpinnerModule


    ],
    exports: [
        HomeComponent,
        MatFormFieldModule,
        MatInputModule
    ]
})

export class HomeModule {
}
