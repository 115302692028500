import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { WipConfigService } from 'app/core/_services';


@Component({
  selector: 'app-pop-up',
  templateUrl: './newWIP-pop-up.component.html',
  styleUrls: ['./newWIP-pop-up.component.scss']
})
export class PopUpComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private WipConfigService: WipConfigService,
    private snackBar: MatSnackBar) { }

  addNewWIPForm = this.formBuilder.group({
    key: [null, Validators.compose([Validators.required])],
    value: [null, Validators.compose([Validators.required])],
    // link: null,
    type: null
  });

  ngOnInit(): void {
    this.resetCreateWipConfigurationForm();
  }

  createWipConfiguration() {

    try {

      // console.log(this.addNewWIPForm.value, "ADD NEW WIP FORM");
      let valuesFromSubmittedForm = this.addNewWIPForm.value;
      let data = {
        key: valuesFromSubmittedForm.key,
        value: valuesFromSubmittedForm.value,
        link: ""
      }

      if (valuesFromSubmittedForm.type === "SHEET") {

        let sheetURL = "https://docs.google.com/spreadsheets/d/" + (valuesFromSubmittedForm.value).toString().trim();
        data.link = sheetURL;

      } else if (valuesFromSubmittedForm.type === "FOLDER") {

        let folderURL = "https://drive.google.com/drive/u/4/folders/" + (valuesFromSubmittedForm.value).toString().trim();
        data.link = folderURL;

      } else {

        data.link = "";

      }

      this.WipConfigService.addWipConfig(data).toPromise().then(response => {
        // console.log(response, "RESPONSE FROM ADD WIP CONFIG");
        this.openSnackBar("WIP CONFIGURATION CREATED SUCCESSFULLY", "SUCCESS");
        this.resetCreateWipConfigurationForm();
      }).catch(err => {
        if (err.status === 302) {
          this.openSnackBar("WIP CONFIGURATION WITH THIS KEY ALREADY EXISTS", "WARNING");
        } else {
          this.openSnackBar("WIP CONFIGURATION CREATION FAILED", "ERROR");
        }
        console.log(err);
      });

    } catch (e) {
      console.log(e);
    }

  }

  resetCreateWipConfigurationForm() {
    this.addNewWIPForm.reset();
  }

  openSnackBar(message, type) {

    if (type === "SUCCESS") {

      const successConfig = new MatSnackBarConfig();
      successConfig.duration = 2000;
      successConfig.verticalPosition = 'top',
        successConfig.panelClass = ['snackbar-success']
      successConfig.horizontalPosition = 'right'

      this.snackBar.open(message, "", successConfig);

    } else if (type === "ERROR") {
      const errorConfig = new MatSnackBarConfig();
      errorConfig.duration = 2000;
      errorConfig.verticalPosition = 'top',
        errorConfig.panelClass = ['snackbar-error']
      errorConfig.horizontalPosition = 'right'

      this.snackBar.open(message, "", errorConfig);
    } else if (type === "WARNING") {
      const warningConfig = new MatSnackBarConfig();
      warningConfig.duration = 2000;
      warningConfig.verticalPosition = 'top',
        warningConfig.panelClass = ['snackbar-warning']
      warningConfig.horizontalPosition = 'right'

      this.snackBar.open(message, "", warningConfig);
    }
  }

}