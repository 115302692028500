import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Response } from '../_models/index';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { toBase64String } from '@angular/compiler/src/output/source_map';

@Injectable()
export class LoginService {
    private userToken = 'powerwip-token';
    private userName = 'powerwip-username';

    constructor(private http: HttpClient, private router: Router) {
    }

    UserLogin(pdata: any): Observable<Response> {
        return this.http.post<Response>(environment.baseAPIUrl + '/user/adminLogin', pdata);
    }

    isAuthenticated(): boolean {
        const token = sessionStorage.getItem(this.userToken);
        const username = sessionStorage.getItem(this.userName);

        if (token != null && token != 'Not authorized' && username != null) {
            console.log("AUTH GUARD CHECK SUCCESS..")
            return true;
        } else {
            console.log("AUTH GUARD CHECK FAILED..")
            return false;
        }
    }

    removeSessionStorage(): void {
        sessionStorage.clear();
    }

    // removeLocalStorage(): void {
    //     localStorage.clear();
    // }

    logout(): void {
        // this.removeLocalStorage();
        this.removeSessionStorage();
        this.router.navigate(['/login']);
    }

    // getUser(): any {
    //     return JSON.parse(localStorage.getItem(this.userInfo));
    // }

    // setUser(userdata: JSON): void {
    //     localStorage.setItem(this.userInfo, JSON.stringify(userdata));
    // }

    startAuth(): any {
        return this.http.get<Response>(environment.wipProcessAPIURL + '/auth', { responseType: 'text' as 'json' });
    }

    // generateAccessToken(url: string): any {

    //     return this.http.post<Response>(environment.wipProcessAPIURL + '/redirect', url, { responseType: 'text' as 'json' })

    // }

    getAccessSession(): any {
        return this.http.get<Response>(environment.wipProcessAPIURL + '/getUserSession', { responseType: 'text' as 'json' })
    }

    logoutUserSession(): any {
        return this.http.get<Response>(environment.wipProcessAPIURL + '/logout', { responseType: 'json' })
    }
}
