<div id="login" fxLayout="column">

    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="login-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img width="128" src="PowerWIPLogo.png">
            </div>

            <div class="title">LOGIN TO YOUR ACCOUNT</div>

            <form name="loginForm" [formGroup]="loginForm" novalidate>

                <mat-form-field  class="disable"  appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="loginForm.get('email').hasError('required')">
                        Email is required
                    </mat-error>
                    <mat-error *ngIf="!loginForm.get('email').hasError('required') &&
                                loginForm.get('email').hasError('email')">
                        Please enter a valid email address
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="disable" appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput  type="password" formControlName="password">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error>
                        Password is required
                    </mat-error>
                </mat-form-field>
                <button mat-raised-button color="accent" class="submit-button" style="width: 220px; background-color:#d1e02b;" aria-label="LOG IN"
                    [disabled]="loginForm.invalid">
                    LOGIN
                </button>

            </form>

            <div class="separator">
                <span class="text">OR</span>
            </div>

            <button mat-raised-button class="submit-button" style="width: 220px; background-color:#d1e02b;" (click)=login()>
                Sign in with Intuit
            </button>

        </div>

    </div>

</div>