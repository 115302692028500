import { Component, OnInit, } from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {​​ DataSource }​​ from '@angular/cdk/table';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogmodalComponent } from '../dialogmodal/dialogmodal.component';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { DialogModalModule } from '../dialogmodal/dialogmodal.module';
import { compact } from 'lodash';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

export interface ChartElement {
    id:number;
    qboName: string; 
    displayName:string;
    category: string;
    glCode: string;   
  }
  
  const ELEMENT_DATA: ChartElement[] = [  
  {   
      id :1 ,
      category: "MATERIALS",
      glCode: "50100 Material:50100-2 Rough-In Material",
      displayName: "Rough-In",
      qboName: "Rough-In"
  },
  {
      id :2,
      category: "MATERIALS",
      glCode: "50100 Material:50100-6 Trim Material",
      displayName: "Trim",
      qboName: "Trim"
  },
  {
    id :3,
      category: "MATERIALS",
      glCode: "50100 Material:50100-7 Start-Up Material",
      displayName: "Start-Up",
      qboName: "Start-Up"
  },
  {
    id :4,
      category: "MATERIALS",
      glCode: "50100 Material:50100-8 Copper Material",
      displayName: "Copper",
      qboName: "Copper"
  },
  {
    id :5,
      category: "MATERIALS123",
      glCode: "50100 Material",
      displayName: "OTHER (Unclassified)",
      qboName: "OTHER (Unclassified)"
  },
  {
    id :6,
      category: "EQUIPMENT1",
      glCode: "50000 Equipment",
      displayName: "EQUIPMENT",
      qboName: "EQUIPMENT"
  },
  {
    id :7,
      category: "SUBCONTRACTOR SOV1",
      glCode: "55100 Subcontractor:55103 Subcontractor Core Drill",
      displayName: "Mobility/Coredrill",
      qboName: "Mobility/Coredrill"
  },
  {
    id :8,
      category: "SUBCONTRACTOR SOV",
      glCode: "55100 Subcontractor:55101 Subcontractor SOV:55101-2 Rough-In Subcontractor",
      displayName: "Rough-In",
      qboName: "Rough-In"
  },
  {
    id :9,
      category: "SUBCONTRACTOR SOV",
      glCode: "55100 Subcontractor:55101 Subcontractor SOV:55101-6 Trim Subcontractor",
      displayName: "Trim",
      qboName: "Trim"
  },
  {
    id :10,
      category: "SUBCONTRACTOR SOV",
      glCode: "55100 Subcontractor:55101 Subcontractor SOV:55101-3 Set AHU Subcontractor",
      displayName: "Set AHU",
      qboName: "Set AHU"
  },
  {
    id :11,
      category: "SUBCONTRACTOR SOV",
      glCode: "55100 Subcontractor:55101 Subcontractor SOV:55101-4 Set Condenser Subcontractor",
      displayName: "Set Condensor",
      qboName: "Set Condensor"
  },
  {
    id :12,
      category: "SUBCONTRACTOR SOV",
      glCode: "55100 Subcontractor:55101 Subcontractor SOV:55101-8 Copper Subcontractor",
      displayName: "Copper",
      qboName: "Copper"
  },
  {
    id :13,
      category: "SUBCONTRACTOR SOV",
      glCode: "55100 Subcontractor:55101 Subcontractor SOV",
      displayName: "OTHER (Unclassified)",
      qboName: "OTHER (Unclassified)"
  },
  {
    id :14,
      category: "SUPERINTENDENT LABOR",
      glCode: "50400 Labor:50400-4 Supervision Labor",
      displayName: "SUPERINTENDENT LABOR",
      qboName: "SUPERINTENDENT LABOR"
  },
  {
    id :15,
      category: "GENERAL CONDITIONS",
      glCode: "50400 Labor:50400-1 Pre-Fab",
      displayName: "Prefab Labor",
      qboName: "Prefab Labor"
  },
  {
    id :16,
      category: "GENERAL CONDITIONS",
      glCode: "50400 Labor:50400-3 Start Up Labor",
      displayName: "Start-Up Labor",
      qboName: "Start-Up Labor"
  },
  {
    id :  17,
      category: "GENERAL CONDITIONS",
      glCode: "50400 Labor:50400-5 General Labor",
      displayName: "General Labor",
      qboName: "General Labor"
  },
  {
    id :18,
      category: "GENERAL CONDITIONS",
      glCode: "50400 Labor:50400-2 Mobility Labor",
      displayName: "Mobility Labor",
      qboName: "Mobility Labor"
  },
  {
    id :19,
      category: "GENERAL CONDITIONS",
      glCode: "50400 Labor",
      displayName: "Labor (unclassified)",
      qboName: "Labor (unclassified)"
  },
  {
    id :20,
      category: "GENERAL CONDITIONS",
      glCode: "55100 Subcontractor:55104 Subcontractor Clean Up Labor",
      displayName: "Job Site Clean Up",
      qboName: "Job Site Clean Up"
  },
  {
    id :21,
      category: "GENERAL CONDITIONS",
      glCode: "55100 Subcontractor:55105 Subcontractor Services",
      displayName: "Service Labor",
      qboName: "Service Labor"
  },
  {
    id :22,
      category: "GENERAL CONDITIONS",
      glCode: "50500 Rental Equipment",
      displayName: "Equipment rental",
      qboName: "Equipment rental"
  },
  {
    id :23,
      category: "GENERAL CONDITIONS",
      glCode: "50600 Rental Container",
      displayName: "Storage",
      qboName: "Storage"
  },
  {
    id :24,
      category: "GENERAL CONDITIONS",
      glCode: "50200 Permits",
      displayName: "Permits",
      qboName: "Permits"
  },
  {
    id :25,
      category: "GENERAL CONDITIONS",
      glCode: "51100 Textura Pay APP Fee",
      displayName: "Textura",
      qboName: "Textura"
  },
  {
    id :26,
      category: "GENERAL CONDITIONS",
      glCode: "50900 Out of Town Cost",
      displayName: "Travel Expenses",
      qboName: "Travel Expenses"
  },
  {
    id :27,
      category: "GENERAL CONDITIONS",
      glCode: "55500 Sales Commission",
      displayName: "Sales Commission",
      qboName: "Sales Commission"
  },
  {
    id :28,
      category: "GENERAL CONDITIONS",
      glCode: "55401 Other Misc Expense",
      displayName: "Other Misc Expense",
      qboName: "Other Misc Expense"
  }  
  ];

@Component({
  selector: 'app-chartofaccounts',
  templateUrl: './chartofaccounts.component.html',
  styleUrls: ['./chartofaccounts.component.scss']
})
export class ChartofaccountsComponent implements OnInit {

  constructor(public dialog: MatDialog,) { }
  displayedColumns: string[] = ['select', 'category','displayName', 'qboName', 'glCode','option'];
  dataSource = new MatTableDataSource<ChartElement>(ELEMENT_DATA);
  selection = new SelectionModel<ChartElement>(true, []);
  // public dialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  public manageCatdialogRef: MatDialogRef<DialogmodalComponent>;
  public viewEditDialogRef: MatDialogRef<DialogmodalComponent>;
  public batchEditDialogRef: MatDialogRef<DialogmodalComponent>;

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    // console.log(numRows,numSelected,this.selection.selected)
     return numSelected === numRows;
    // return this.selection.selected
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: ChartElement): string {
    if (!row) {  
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
  }

  ngOnInit(): void {
  }
  managecat(): void {
    try {
        const manageCatdialogRef = this.dialog.open(DialogmodalComponent, {
            width: "800px",
            maxHeight: "1000px",
            data: {
              key:"category"
            },
        });
        manageCatdialogRef.afterClosed().subscribe((result) => { 
          console.log(result)
        });
    } catch (e) {    
      console.log(e)
    }
}

viewEdit(element): void {
  try {
    // console.log(this.selection.selected);
    console.log()
      const viewEditDialogRef = this.dialog.open(DialogmodalComponent, {
          width: "800px",
          maxHeight: "1000px",
          data: {
            key:"viewedit",
            selectedData:[element]
             
          },
      });
      viewEditDialogRef.afterClosed().subscribe((result) => { });
  } catch (e) {      
    console.log(e)
  }
}

batchEdit(): void {
  try {
    console.log(this.selection.selected);
      const batchEditDialogRef = this.dialog.open(DialogmodalComponent, {
        
          width: "800px",
          maxHeight: "1000px",
          data: {
            key:"batchedit",
            selectedData:this.selection.selected,             
          },
      });
      batchEditDialogRef.afterClosed().subscribe((result) => { });
  } catch (e) {     
    console.log(e)
  }
}

moveUp(){
  let data;
  this.selection.selected.forEach(item => {
    let index: number = ELEMENT_DATA.findIndex(d => d === item);
    console.log(ELEMENT_DATA.findIndex(d => d === item));
    ELEMENT_DATA.splice(index,1)
    //this.dataSource = new MatTableDataSource<ChartElement>(ELEMENT_DATA);
  });
 
for (let index = 0; index < this.selection.selected.length; index++) { 
  ELEMENT_DATA.splice(0,0,this.selection.selected[(this.selection.selected.length-1)-index])
}

this.dataSource = new MatTableDataSource<ChartElement>(ELEMENT_DATA);
this.selection = new SelectionModel<ChartElement>(true, []);
}

moveDown(){
  let data;
  this.selection.selected.forEach(item => {
    let index: number = ELEMENT_DATA.findIndex(d => d === item);
    console.log(ELEMENT_DATA.findIndex(d => d === item));
    ELEMENT_DATA.splice(index,1)
    //this.dataSource = new MatTableDataSource<ChartElement>(ELEMENT_DATA);
  });
 
for (let index = 0; index < this.selection.selected.length; index++) {
  
  ELEMENT_DATA.push(this.selection.selected[index])
}
this.dataSource = new MatTableDataSource<ChartElement>(ELEMENT_DATA);
this.selection = new SelectionModel<ChartElement>(true, []);
}

}

