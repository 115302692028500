import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseDemoModule, FuseSidebarModule } from '@fuse/components';
import { DashboardComponent } from './dashboard.component';
import {AuthGuard} from '../../core/_guards/auth.guard'

const routes = [
    {
        path     : 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        data: {title: 'dashboard',
                metaDesc :''
    }
    }
];

@NgModule({
    declarations: [
        DashboardComponent,
        
    ],
    imports     : [
        RouterModule.forChild(routes),

        FuseSidebarModule,
        FuseSharedModule,
        MatButtonModule,
        FuseDemoModule
    ],


    exports     : [
        DashboardComponent
    ],
})

export class DashboardModule
{
}
