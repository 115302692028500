import { LoginService } from '../_services/index';
import { Injectable } from '@angular/core';

import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Route,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Auth } from 'aws-amplify';
@Injectable()
// export class AuthGuard implements CanActivate {
//   constructor(private router: Router,
//     private _loginService: LoginService) { }
//   canActivate(
//     next: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot
//   ): Observable<boolean> | Promise<boolean> | boolean {
//     if (this._loginService.isAuthenticated()) {
//       return true;
//     }
//     console.log("testauthcanactive")
//     return new Promise((resolve) => {

//       // Auth.currentAuthenticatedUser({
//       //     bypassCache: false
//       //   })
//       //   .then((user) => {
//       //     if(user){
//       //      // console.log("testauth")
//       //       resolve(true);
//       //     }
//       //   })
//       //   .catch((e) => {

//       //    // console.log("testautherror",e)
//       //     this.router.navigate(['/login']);
//       //     resolve(false);
//       //   });
//     });
//     // // navigate to login page
//     // this.router.navigate(['/login']);
//     // // you can save redirect url so after authing we can move them back to the page they requested
//     // return false;
//   }
// }

export class AuthGuard implements CanActivate {

  constructor(private _loginService: LoginService, private router: Router) { }

  canActivate(

      next: ActivatedRouteSnapshot,

      state: RouterStateSnapshot

  ): Observable<boolean> | Promise<boolean> | boolean {

      if (this._loginService.isAuthenticated()) {

          return true;

      }

      // console.log("this.routerasdfa", this.router, state, next);

      // navigate to login page

      // this.router.navigate(['/login']);

      this.router.navigate(['/login']);

      // you can save redirect url so after authing we can move them back to the page they requested

      return false;

  }
}
